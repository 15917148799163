import { Navbar, Nav, NavLink, Container, NavItem, Row, Col } from "react-bootstrap";
import './CustomNavbar.css'
import CustomNavbarLogoFader from "./CustomNavbarLogoFader";
import brandLogo1 from './assets/is-property-simple-logo-04-bigger.png'
import brandLogo2 from './assets/is-property-simple-logo-02-bigger.png'

const CustomNavbar = () => {
    return (
        <Row>
            <Col>
                {/* <Image src={brandLogo2} style={{maxHeight: '100%', width: '30vw'}}></Image> */}
                <CustomNavbarLogoFader logo1={brandLogo1} logo2={brandLogo2} style={{maxHeight: '100%', width: '30vw'}}></CustomNavbarLogoFader>
            </Col>
            <Col xs='8' sm='8' md='8' lg='8'>
                <Navbar sticky="top" bg="primary" data-bs-theme="dark">
                    <Container>
                        <div className="d-block w-100">
                        <Nav fill>
                            <NavItem>
                                <NavLink href="/" className="custom-navlink"><p>Home</p></NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="mailto:info@ispropertysimple.co.uk" className="custom-navlink"><p>Contact Us</p></NavLink>
                            </NavItem>
                        </Nav>
                        </div>
                    </Container>
                </Navbar>
            </Col>
        </Row>
    );
}
 
export default CustomNavbar;