import { Navbar, Container, Nav, NavItem, Row, Col, Image } from "react-bootstrap";
import './CustomFooter.css'
import brandLogo from './assets/is-property-simple-logo-04-bigger.png'
import prsLogo from './assets/prs-logo.png'


const CustomFooter = () => {
    return ( 
        <Row className="d-flex bg-dark mx-auto">
            <Col className="d-inline-flex flex-row justify-content-around align-items-center">
                <div>
                    <Image src={brandLogo} style={{maxHeight: '100%', width: '19vw'}}></Image>
                </div>
                <div>
                    <Image src={prsLogo} style={{maxHeight: '100%', width: '8vw'}}></Image>
                </div>
            </Col>
            <Col xs='8' sm='8' md='8' lg='8'>
                <Navbar bg="dark" data-bs-theme="dark">
                <Container>
                    <Nav className="d-inline-flex flex-row justify-content-around align-items-center w-100">
                        <NavItem>
                            <p className={'footer-message text-center'}>© Is Property Simple<br />{new Date().getFullYear()}</p>
                        </NavItem>
                        <NavItem>
                            <p className={'footer-message'}>is Property Simple Ltd<br />Victory House<br />Chobham Street<br />Luton<br />LU1 3BS</p>
                            <a href="tel:01582383446"><p className="footer-message">01582383446</p></a>
                        </NavItem>
                    </Nav>
                </Container>
                </Navbar>
            </Col>
        </Row>
     );
}
 
export default CustomFooter;