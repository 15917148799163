import { Container, Row, Col, Image } from "react-bootstrap"
import './HomeBanner.css'

const HomeBanner = ({imgSrc, className, style}) => {
    return(
        <div className="home-banner">
            <Container fluid>
                <Row>
                    <Col xs={1} sm={1} md={1} lg={1} ></Col>
                    <Col xs={10} sm={10} md={10} lg={10} >
                        {/* <Image src={imgSrc} className={`home-banner-image ${className}`} style={{maxWidth: '35vw'}} rounded/> */}
                        <Image src={imgSrc} className={`home-banner-image ${className}`} style={style} rounded/>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} ></Col>
                </Row>
            </Container>
        </div>
    )
}

export default HomeBanner;