import React, { useState, useEffect } from 'react';
import { Fade, Image } from 'react-bootstrap';

const CustomNavbarLogoFader = ({ logo1, logo2, style}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShow(false);
      setTimeout(() => {
        setCurrentImage(prevImage => (prevImage === 0 ? 1 : 0));
        setShow(true);
      }, 200); // Delay to allow fade out before switching images
    }, 4000); // Switch every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="image-fader">
      <Fade in={show}>
        <div>
          <Image
            src={currentImage === 0 ? logo1 : logo2}
            fluid
            alt="Fading Image"
            style={style}
          />
        </div>
      </Fade>
    </div>
  );
};

export default CustomNavbarLogoFader;