import {Stack, Container, Row, Col, Button, Fade} from "react-bootstrap";
// import '../node_modules/bootstrap/scss/bootstrap.scss'
import './scss/Custom.scss'
import './Home.css'
import CustomNavbar from "./CustomNavbar";
import { useEffect, useState } from "react";
import HomeBanner from "./HomeBanner";
import bannerImage from './assets/outdoor-house-12.jpg'
import servicesImage1 from './assets/pexels-heyho-7018401.jpg'
import servicesImage2 from './assets/pexels-falling4utah-2724748.jpg'
import servicesImage3 from './assets/pexels-ketut-subiyanto-4963436.jpg'
import howItWorksImage from './assets/pexels-pu-ca-adryan-163345030-26732551.jpg'
import CustomFooter from "./CustomFooter";

const Home = () => {
    const [isNavBarVisible, setIsNavBarVisible] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(false)

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsNavBarVisible(true);
      }, 1000); // Delay to ensure the transition effect works
  
      return () => clearTimeout(timer);
    }, []);

    return (
        <Stack gap={2} className="home-background">
            <Fade in={isNavBarVisible} className="fade-navbar" onEntered={() => setIsContentVisible(true)}>
                <div>
                    <CustomNavbar />
                </div>
            </Fade>
            <div className="home-content">
                <Fade in={isContentVisible} appear={true} >
                    <div className="fade-content">
                        <Container className="d-inline-flex flex-row justify-content-between my-5" fluid>
                            <Stack className="justify-content-center" direction="horizontal" gap={0}>
                                <Stack className="my-auto w-100 h-100" gap={1}>
                                    <h4 className="home-hero-message">Assured Rent, No-cost Cosmetic Refurbs with Purchase Options</h4>
                                    <h1 className="home-hero-title">We<br />make</h1>
                                    <h1 className="home-hero-title-emphasised">Property<br />Simple</h1>
                                    <h4 className="home-hero-message">You enjoy the simplicity</h4>
                                    <Button className="home-get-in-touch-button" variant="secondary" size="lg"><a href="mailto:info@ispropertysimple.co.uk" className="home-get-in-touch-button-text">Get in Touch</a></Button>
                                </Stack>
                                <HomeBanner imgSrc={bannerImage} style={{maxWidth: '39vw'}} />
                            </Stack>
                        </Container>
                        <Container className="home-ethos-container py-3" fluid>
                            <h2 className="home-ethos-title">We turn the hassle of property management into<br />effortless ease.</h2>
                            <p className="home-ethos-subtitle">is Property Simple offers seamless property management and short-term accommodation services across Luton and surrounding areas. We specialise in hassle-free property management for landlords and corporate accommodations tailored for business travellers.<br /><br />See what we offer below!</p>
                        </Container>
                        <Container className="home-service-container" fluid>
                            <div className="d-flex flex-row align-items-center">
                                <Col>
                                    <HomeBanner imgSrc={servicesImage1} style={{maxWidth: '35vw'}}/>
                                </Col>
                                <Col>
                                    <Container className="d-flex flex-column justify-content-evenly h-100" fluid>
                                        <Row>
                                            <h2 className="home-services-title text-start">We provide Assured Rent</h2>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">We specialise in providing premium serviced accommodation. With our extensive local knowledge and expertise, we ensure top-tier service for both landlords and clients. Landlords receive guaranteed rent for 3-5 years, with no hidden fees and no commission.</p>
                                        </Row>
                                    </Container>
                                </Col>
                            </div>
                        </Container>
                        <Container className="home-service-container" fluid>
                            <div className="d-flex flex-row-reverse align-items-center">
                                <Col>
                                    <HomeBanner className="d-inline" imgSrc={servicesImage2} style={{maxWidth: '35vw'}}/>
                                </Col>
                                <Col>
                                    <Container className="d-flex flex-column justify-content-evenly h-100" fluid>
                                        <Row>
                                            <h2 className="home-services-title text-start">We perform Cosmetic Refurbs at No Cost</h2>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">Our team will complete a no-cost cosmetic upgrade and fully furnish your property to meet the expectations of our professional clients. This means you’ll benefit from increased property value and the reassurance that your property will be returned in top condition.</p>
                                        </Row>
                                    </Container>
                                </Col>
                            </div>
                        </Container>
                        <Container className="home-service-container" fluid>
                            <div className="d-flex flex-row align-items-center">
                                <Col>
                                    <HomeBanner className="d-inline" imgSrc={servicesImage3} style={{maxWidth: '35vw'}}/>
                                </Col>
                                <Col>
                                    <Container className="d-flex flex-column justify-content-evenly h-100" fluid>
                                        <Row>
                                            <h2 className="home-services-title text-start">We offer Purchase Options</h2>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">At the end of our agreement, we present you several options, including the possibility of us purchasing your property if it aligns with your long-term goals. No agents, no fees, no hassle!</p>
                                        </Row>
                                    </Container>
                                </Col>
                            </div>
                        </Container>
                        <Container className="home-service-container" fluid>
                            <div className="d-flex flex-row-reverse">
                                <Col className="align-self-center">
                                    <HomeBanner imgSrc={howItWorksImage} style={{maxWidth: '35vw'}} />
                                </Col>
                                <Col>
                                    <Container className="d-flex flex-column justify-content-evenly h-100" fluid>
                                        <Row>
                                            <h2 className="home-services-title text-start text-decoration-underline">How it works</h2>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start mb-3">We simplify the landlord onboarding process to make it as fast and efficient as possible.</p>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">➤ 3-5 year of agreement with guaranteed rent.</p>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">➤ No-cost refurb completed by our team.</p>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">➤ We pay you whilst our professional clients experience a comfortable stay in your property.</p>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">➤ Management and maintenance of your property.</p>
                                        </Row>
                                        <Row>
                                            <p className="home-services-text text-start">➤ End of agreement purchase options.</p>
                                        </Row>
                                    </Container>
                                </Col>
                            </div>
                        </Container>
                        <Container className="home-ethos-container py-3" fluid>
                            <p className="home-ethos-subtitle">Are we the best solution for you?</p>
                            <h2 className="home-ethos-title">Arrange a call with us now!</h2>
                            <div className="d-grid mx-auto my-4 home-arrange-call-button-container">
                                <Button href="mailto:info@ispropertysimple.co.uk" variant="light" size="sm"><span className="home-arrange-call-button-text">Arrange Here</span></Button>
                            </div>
                        </Container>
                    </div>
                </Fade>
                <CustomFooter />
            </div>
        </Stack>
    );
}
 
export default Home;