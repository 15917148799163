import './App.css';
/* The following line can be included in your src/index.js or App.js file */
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/Custom.scss'
import Home from './Home';

function App() {

  return (
    <div className="App">
      <div className='content'>
        <Home />
      </div>
    </div>
  );
}

export default App;
